import React, { useEffect, useState } from "react";
import cn from 'classnames';

import Button from "../element/Button";
import { testMandatoriesFns } from "../test-mandatories";

import "./stepper-buttons.scss";

const StepperButtons = ({
  nextStep,
  prevStep,
  currentStep,
  localState,
  isSumsubForm,
}) => {
  const firstComponent = isSumsubForm
    ? currentStep?.id === "sumsub-id-verification"
    : currentStep?.id === "about-you";
  const lastComponent = currentStep?.id === "review-and-agreement";
  const noButton = currentStep?.name === "Account Setup";
  const noPrevButton = isSumsubForm
    ? currentStep?.id === "business-deal"
    : currentStep?.id === "about-you";
  const buttonsBlockStyle = !firstComponent
    ? "footer-buttons"
    : "footer-button";

  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    // check for agreement
    switch (currentStep?.id) {
      case "about-you":
        if (testMandatoriesFns.aboutYou(localState.profile)) {
          setDisabledButton(false);
        } else {
          setDisabledButton(true);
        }
        break;
      case "id-verification":
        if (
          testMandatoriesFns.IDVerification(
            localState.profile,
            localState.files
          )
        ) {
          setDisabledButton(false);
        } else {
          setDisabledButton(true);
        }
        break;
      case "sumsub-id-verification":
        if (localState.sumsubDocsAreVerified) {
          setDisabledButton(false);
        } else {
          setDisabledButton(true);
        }
        break;
      case "business-deal":
        if (
          testMandatoriesFns.businessDeal(localState.profile, localState.limits)
        ) {
          setDisabledButton(false);
        } else {
          setDisabledButton(true);
        }
        break;
      case "review-and-agreement":
        if (testMandatoriesFns.agreement(localState)) {
          setDisabledButton(false);
        } else {
          setDisabledButton(true);
        }
        break;

      default:
        setDisabledButton(true);
        break;
    }
  }, [currentStep, localState]);

  if (!noButton)
    return (
      <div className={`footer-wrapper`}>
        <div className={buttonsBlockStyle}>
          {!firstComponent && !noPrevButton && (
            <Button id="step-button-previous" onClick={prevStep} className="main-button prev-button">
              Previous
            </Button>
          )}
          <Button
            id="step-button-next-submit"
            disabled={disabledButton}
            className={cn({
              "submit-app-btn": lastComponent,
            })}
            onClick={nextStep}
          >
            {lastComponent ? "Submit Application" : "Next step"}
          </Button>
        </div>
      </div>
    );
  return null;
};

export default StepperButtons;
